.Profile-Photo {
    width: 20vw;
    height: auto;
    border-radius: 50%;
    -webkit-box-shadow: -1px 0px 17px 3px rgba(199, 197, 197, 1);
    -moz-box-shadow: -1px 0px 17px 3px rgba(199, 197, 197, 1);
    box-shadow: -1px 0px 17px 3px rgba(199, 197, 197, 1);
}


.Section-Top {
    position: absolute;
    top: 20px;
    text-align: center;
    width: 100%;

}

.Section-Bottom {
    position: absolute;
    bottom: 20px;
    text-align: center;
    width: 100%;
}

.Section-Body {
    display: flex;
    flex-direction: column;
    align-content: center;
}


.Multi-Section-Body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}


.Sub-Section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

}

.Section-Row-Centered {
    justify-content: center;
    margin: auto;
}


.Footer-Container {
    bottom: 0;
    position: absolute;
    width: 100%;
    justify-content: center;
    padding-bottom: 2vw;
}


.Photo-Container {

}

.Welcome-Text {
    color: #d4d4dc;
    font-size: 4vh;
}

.Sub-Section-Title {
    color: #d4d4dc;
    font-size: 4vh;
    font-weight: 400;
    letter-spacing: 2px;

}

.header-bar {
    background: #feda6a;
    height: 4px;
    margin: 25px 0 75px 0;
    width: 70px;
}

.Intro-Text {
    color: #d4d4dc;
    font-size: 4vh;
}

.Name-Text {
    font-weight: bolder;
    color: #feda6a;
}

.Skills-Text-Style {
    font-weight: bolder;
    color: #feda6a;
    font-size: 4vh;

}

.Down-Button {
    color: #d4d4dc;
    flex-direction: column;
}

.Horiz-Buttons {
    flex-direction: row;
}

.Image-Link {
    color: #d4d4dc;
}

.Image-Link :hover {
    color: #feda6a;
}

.Email-Text {
    font-size: large;
    letter-spacing: 2px;

}