.btn.focus, .btn:focus {
    outline: 0;
    box-shadow: none!important;
}



.Next-Text {
    font-size: 1vw;
}

.Down-Icon {
    margin-top: 10px;
}


